<script setup lang="ts">
const navigation = inject<Ref<any[]>>("navigation", ref([]));
const { auth } = useSupabaseClient();
const user = useSupabaseUser();
const client = useSupabaseClient();

// const { data: userData, isLoading: isProfileLoading } = useQuery({
//   queryKey: ["profile"],
//   queryFn: async () => {
//     const { data, error } = await client
//       .from("profiles")
//       .select("*")
//       .eq("id", user.value.id)
//       .single();

//     if (error) {
//       return Promise.reject(error);
//     }

//     return data;
//   },
//   enabled: computed(() => !!user.value),
// });

const isOpen = ref(false);

const links = computed(() =>
  user.value
    ? [
        {
          label: "Accueil",
          to: "/",
          icon: "i-material-symbols-house",
        },
      ]
    : []
);

const transformedLinks = computed(() =>
  links.value.map((link) => ({
    ...link,
    click: () => (isOpen.value = false),
  }))
);

const url = window.location.origin + "/?provider=google";
</script>

<template>
  <header
    class="bg-white/75 dark:bg-black/75 backdrop-blur border-b border-gray-200 dark:border-gray-800 -mb-px sticky top-0 z-50"
  >
    <div
      class="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl flex items-center justify-between gap-3 h-16"
    >
      <!-- <div class="inline-flex items-center gap-1">
        <UIcon name="i-heroicons-circle-stack-20-solid" class="size-5" />
        <span class="">{{ userData?.credits }}</span>
        <UButton class="ml-4" icon="i-heroicons-plus-20-solid" size="2xs"
          ></UButton
        >
      </div> -->
      <div class="lg:flex-1 flex items-center gap-1.5 md:flex-1">
        <a
          aria-current="page"
          href="/"
          class="router-link-active router-link-exact-active flex-shrink-0 font-bold text-xl text-gray-900 dark:text-white flex items-end gap-1.5"
          aria-label="SyncPost"
        >
        SyncPost
        </a>
      </div>
      <div class="flex items-center justify-end lg:flex-1 gap-1.5 md:flex-1">
        <!-- <UColorModeButton /> -->
        <DarkMode />
        <Profile v-if="user" />

        <UButton
          v-if="!user"
          trailing-icon="i-heroicons-arrow-right-20-solid"
          color="gray"
          @click="
            auth.signInWithOAuth({
              provider: 'google',
              options: {
                redirectTo: url,
              },
            })
          "
        >
          Connexion avec Google
        </UButton>
      </div>
    </div>
  </header>
</template>
