<script setup lang="ts">
const router = useRouter();
const user = useSupabaseUser();
const { auth } = useSupabaseClient();

const logoutAndRedirect = async () => {
  await auth.signOut();
  router.push("/login");
};

const items = computed(() => [
  [
    {
      label: user.value?.email,
      slot: "account",
      disabled: true,
    },
  ],
  // [
  //   {
  //     label: "Paramètres",
  //     to: "/settings",
  //     // disabled: true,
  //     icon: "i-heroicons-cog-8-tooth",
  //   },
  // ],
  [
    {
      label: "Déconnexion",
      icon: "i-heroicons-arrow-left-on-rectangle",
      click: logoutAndRedirect,
    },
  ],
]);
</script>

<template>
  <UDropdown v-slot="{ open }" :items="items">
    <!-- <UButton
      color="gray"
      variant="ghost"
      :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      class="w-full"
    > -->
    <!-- <UAvatar icon="i-material-symbols-person" /> -->
    <!-- <UAvatar src="https://avatars.githubusercontent.com/u/739984?v=4" /> -->
    <UAvatar
      :alt="user.email"
      :src="user.user_metadata?.avatar_url"
      class="select-none"
    />
    <!-- <UAvatar v-if="user.user_metadata?.avatar_url" src="
https://lh3.googleusercontent.com/a/ACg8ocLTsCIssdIVb8eeOS4AMzWz-Xwk2Tp-s2vtzoIMdOxQIEhXQrU=s96-c"/> -->
  </UDropdown>
</template>
